// extracted by mini-css-extract-plugin
export var cssMain = "chip8Page-module--cssMain--3vxsB";
export var title = "chip8Page-module--title--2wMmR";
export var desc = "chip8Page-module--desc--1kkid";
export var pText = "chip8Page-module--pText--23yX5";
export var pTitle = "chip8Page-module--pTitle--3Kbts";
export var textBox = "chip8Page-module--textBox--2946a";
export var boxText = "chip8Page-module--boxText--3QnRc";
export var instructionLine = "chip8Page-module--instructionLine--22rVM";
export var instructionTitle = "chip8Page-module--instructionTitle--29rCT";
export var centerDiv = "chip8Page-module--centerDiv--752J8";
export var canvasBorder = "chip8Page-module--canvasBorder--3TeJ9";
export var canvasNoBorder = "chip8Page-module--canvasNoBorder--v1Uz5";
export var canvasDiv = "chip8Page-module--canvasDiv--1swOu";
export var chipButton = "chip8Page-module--chipButton--3Brnh";
export var line = "chip8Page-module--line--3EH8E";
export var buttonFlex = "chip8Page-module--buttonFlex--45BYJ";